
var ortWasm = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasm) {
  ortWasm = ortWasm || {};


null;var b;b||(b=typeof ortWasm !== 'undefined' ? ortWasm : {});var aa,k;b.ready=new Promise(function(a,c){aa=a;k=c});var ba=Object.assign({},b),p="./this.program",t=(a,c)=>{throw c;},ca="object"==typeof window,u="function"==typeof importScripts,x="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,y="",z,A,C,fs,D,E;
if(x)y=u?require("path").dirname(y)+"/":__dirname+"/",E=()=>{D||(fs=require("fs"),D=require("path"))},z=function(a,c){E();a=D.normalize(a);return fs.readFileSync(a,c?void 0:"utf8")},C=a=>{a=z(a,!0);a.buffer||(a=new Uint8Array(a));return a},A=(a,c,e)=>{E();a=D.normalize(a);fs.readFile(a,function(f,g){f?e(f):c(g.buffer)})},1<process.argv.length&&(p=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("uncaughtException",function(a){if(!(a instanceof F))throw a;}),process.on("unhandledRejection",
function(a){throw a;}),t=(a,c)=>{if(noExitRuntime)throw process.exitCode=a,c;c instanceof F||G("exiting due to exception: "+c);process.exit(a)},b.inspect=function(){return"[Emscripten Module object]"};else if(ca||u)u?y=self.location.href:"undefined"!=typeof document&&document.currentScript&&(y=document.currentScript.src),_scriptDir&&(y=_scriptDir),0!==y.indexOf("blob:")?y=y.substr(0,y.replace(/[?#].*/,"").lastIndexOf("/")+1):y="",z=a=>{var c=new XMLHttpRequest;c.open("GET",a,!1);c.send(null);return c.responseText},
u&&(C=a=>{var c=new XMLHttpRequest;c.open("GET",a,!1);c.responseType="arraybuffer";c.send(null);return new Uint8Array(c.response)}),A=(a,c,e)=>{var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=()=>{200==f.status||0==f.status&&f.response?c(f.response):e()};f.onerror=e;f.send(null)};var da=b.print||console.log.bind(console),G=b.printErr||console.warn.bind(console);Object.assign(b,ba);ba=null;b.thisProgram&&(p=b.thisProgram);b.quit&&(t=b.quit);var H;
b.wasmBinary&&(H=b.wasmBinary);var noExitRuntime=b.noExitRuntime||!0;"object"!=typeof WebAssembly&&I("no native wasm support detected");var J,K=!1,ea="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function fa(a,c,e){c>>>=0;var f=c+e;for(e=c;a[e]&&!(e>=f);)++e;if(16<e-c&&a.buffer&&ea)return ea.decode(a.subarray(c,e));for(f="";c<e;){var g=a[c++];if(g&128){var h=a[c++]&63;if(192==(g&224))f+=String.fromCharCode((g&31)<<6|h);else{var l=a[c++]&63;g=224==(g&240)?(g&15)<<12|h<<6|l:(g&7)<<18|h<<12|l<<6|a[c++]&63;65536>g?f+=String.fromCharCode(g):(g-=65536,f+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else f+=String.fromCharCode(g)}return f}function L(a,c){return(a>>>=0)?fa(M,a,c):""}
function N(a,c,e,f){e>>>=0;if(!(0<f))return 0;var g=e;f=e+f-1;for(var h=0;h<a.length;++h){var l=a.charCodeAt(h);if(55296<=l&&57343>=l){var q=a.charCodeAt(++h);l=65536+((l&1023)<<10)|q&1023}if(127>=l){if(e>=f)break;c[e++>>>0]=l}else{if(2047>=l){if(e+1>=f)break;c[e++>>>0]=192|l>>6}else{if(65535>=l){if(e+2>=f)break;c[e++>>>0]=224|l>>12}else{if(e+3>=f)break;c[e++>>>0]=240|l>>18;c[e++>>>0]=128|l>>12&63}c[e++>>>0]=128|l>>6&63}c[e++>>>0]=128|l&63}}c[e>>>0]=0;return e-g}
function O(a){for(var c=0,e=0;e<a.length;++e){var f=a.charCodeAt(e);127>=f?c++:2047>=f?c+=2:55296<=f&&57343>=f?(c+=4,++e):c+=3}return c}var ha,P,M,Q,R;function ia(){var a=J.buffer;ha=a;b.HEAP8=P=new Int8Array(a);b.HEAP16=new Int16Array(a);b.HEAP32=Q=new Int32Array(a);b.HEAPU8=M=new Uint8Array(a);b.HEAPU16=new Uint16Array(a);b.HEAPU32=R=new Uint32Array(a);b.HEAPF32=new Float32Array(a);b.HEAPF64=new Float64Array(a)}var ja=[],ka=[],la=[];function ma(){var a=b.preRun.shift();ja.unshift(a)}
var S=0,T=null,U=null;function I(a){if(b.onAbort)b.onAbort(a);a="Aborted("+a+")";G(a);K=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");k(a);throw a;}function na(){return V.startsWith("data:application/octet-stream;base64,")}var V;V="ort-wasm.wasm";if(!na()){var oa=V;V=b.locateFile?b.locateFile(oa,y):y+oa}function pa(){var a=V;try{if(a==V&&H)return new Uint8Array(H);if(C)return C(a);throw"both async and sync fetching of the wasm failed";}catch(c){I(c)}}
function qa(){if(!H&&(ca||u)){if("function"==typeof fetch&&!V.startsWith("file://"))return fetch(V,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+V+"'";return a.arrayBuffer()}).catch(function(){return pa()});if(A)return new Promise(function(a,c){A(V,function(e){a(new Uint8Array(e))},c)})}return Promise.resolve().then(function(){return pa()})}function F(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}
function W(a){for(;0<a.length;)a.shift()(b)}function ra(a){this.sa=a-24;this.Ia=function(c){R[this.sa+4>>2>>>0]=c};this.Ba=function(c){R[this.sa+8>>2>>>0]=c};this.Ga=function(){Q[this.sa>>2>>>0]=0};this.Aa=function(){P[this.sa+12>>0>>>0]=0};this.Ha=function(){P[this.sa+13>>0>>>0]=0};this.ya=function(c,e){this.za();this.Ia(c);this.Ba(e);this.Ga();this.Aa();this.Ha()};this.za=function(){R[this.sa+16>>2>>>0]=0}}var sa=0;function ta(a){var c=O(a)+1,e=ua(c);e&&N(a,P,e,c);return e}
function va(a,c,e){function f(w){return(w=w.toTimeString().match(/\(([A-Za-z ]+)\)$/))?w[1]:"GMT"}var g=(new Date).getFullYear(),h=new Date(g,0,1),l=new Date(g,6,1);g=h.getTimezoneOffset();var q=l.getTimezoneOffset();Q[a>>2>>>0]=60*Math.max(g,q);Q[c>>2>>>0]=Number(g!=q);a=f(h);c=f(l);a=ta(a);c=ta(c);q<g?(R[e>>2>>>0]=a,R[e+4>>2>>>0]=c):(R[e>>2>>>0]=c,R[e+4>>2>>>0]=a)}function wa(a,c,e){wa.xa||(wa.xa=!0,va(a,c,e))}var Aa={};
function Ba(){if(!Ca){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:p||"./this.program"},c;for(c in Aa)void 0===Aa[c]?delete a[c]:a[c]=Aa[c];var e=[];for(c in a)e.push(c+"="+a[c]);Ca=e}return Ca}var Ca,Da=[null,[],[]];
function Ea(){if("object"==typeof crypto&&"function"==typeof crypto.getRandomValues){var a=new Uint8Array(1);return()=>{crypto.getRandomValues(a);return a[0]}}if(x)try{var c=require("crypto");return()=>c.randomBytes(1)[0]}catch(e){}return()=>I("randomDevice")}function X(a,c){X.wa||(X.wa=Ea());for(var e=0;e<c;e++)P[a+e>>0>>>0]=X.wa();return 0}function Y(a){return 0===a%4&&(0!==a%100||0===a%400)}var Fa=[31,29,31,30,31,30,31,31,30,31,30,31],Ga=[31,28,31,30,31,30,31,31,30,31,30,31];
function Ha(a){var c=Array(O(a)+1);N(a,c,0,c.length);return c}
function Ia(a,c,e,f){function g(d,m,n){for(d="number"==typeof d?d.toString():d||"";d.length<m;)d=n[0]+d;return d}function h(d,m){return g(d,m,"0")}function l(d,m){function n(xa){return 0>xa?-1:0<xa?1:0}var B;0===(B=n(d.getFullYear()-m.getFullYear()))&&0===(B=n(d.getMonth()-m.getMonth()))&&(B=n(d.getDate()-m.getDate()));return B}function q(d){switch(d.getDay()){case 0:return new Date(d.getFullYear()-1,11,29);case 1:return d;case 2:return new Date(d.getFullYear(),0,3);case 3:return new Date(d.getFullYear(),
0,2);case 4:return new Date(d.getFullYear(),0,1);case 5:return new Date(d.getFullYear()-1,11,31);case 6:return new Date(d.getFullYear()-1,11,30)}}function w(d){var m=d.qa;for(d=new Date((new Date(d.ra+1900,0,1)).getTime());0<m;){var n=d.getMonth(),B=(Y(d.getFullYear())?Fa:Ga)[n];if(m>B-d.getDate())m-=B-d.getDate()+1,d.setDate(1),11>n?d.setMonth(n+1):(d.setMonth(0),d.setFullYear(d.getFullYear()+1));else{d.setDate(d.getDate()+m);break}}n=new Date(d.getFullYear()+1,0,4);m=q(new Date(d.getFullYear(),
0,4));n=q(n);return 0>=l(m,d)?0>=l(n,d)?d.getFullYear()+1:d.getFullYear():d.getFullYear()-1}var v=Q[f+40>>2>>>0];f={Ea:Q[f>>2>>>0],Da:Q[f+4>>2>>>0],ta:Q[f+8>>2>>>0],va:Q[f+12>>2>>>0],ua:Q[f+16>>2>>>0],ra:Q[f+20>>2>>>0],la:Q[f+24>>2>>>0],qa:Q[f+28>>2>>>0],Ja:Q[f+32>>2>>>0],Ca:Q[f+36>>2>>>0],Fa:v?L(v):""};e=L(e);v={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y",
"%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var r in v)e=e.replace(new RegExp(r,"g"),v[r]);var ya="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),za="January February March April May June July August September October November December".split(" ");v={"%a":function(d){return ya[d.la].substring(0,3)},"%A":function(d){return ya[d.la]},"%b":function(d){return za[d.ua].substring(0,
3)},"%B":function(d){return za[d.ua]},"%C":function(d){return h((d.ra+1900)/100|0,2)},"%d":function(d){return h(d.va,2)},"%e":function(d){return g(d.va,2," ")},"%g":function(d){return w(d).toString().substring(2)},"%G":function(d){return w(d)},"%H":function(d){return h(d.ta,2)},"%I":function(d){d=d.ta;0==d?d=12:12<d&&(d-=12);return h(d,2)},"%j":function(d){for(var m=0,n=0;n<=d.ua-1;m+=(Y(d.ra+1900)?Fa:Ga)[n++]);return h(d.va+m,3)},"%m":function(d){return h(d.ua+1,2)},"%M":function(d){return h(d.Da,
2)},"%n":function(){return"\n"},"%p":function(d){return 0<=d.ta&&12>d.ta?"AM":"PM"},"%S":function(d){return h(d.Ea,2)},"%t":function(){return"\t"},"%u":function(d){return d.la||7},"%U":function(d){return h(Math.floor((d.qa+7-d.la)/7),2)},"%V":function(d){var m=Math.floor((d.qa+7-(d.la+6)%7)/7);2>=(d.la+371-d.qa-2)%7&&m++;if(m)53==m&&(n=(d.la+371-d.qa)%7,4==n||3==n&&Y(d.ra)||(m=1));else{m=52;var n=(d.la+7-d.qa-1)%7;(4==n||5==n&&Y(d.ra%400-1))&&m++}return h(m,2)},"%w":function(d){return d.la},"%W":function(d){return h(Math.floor((d.qa+
7-(d.la+6)%7)/7),2)},"%y":function(d){return(d.ra+1900).toString().substring(2)},"%Y":function(d){return d.ra+1900},"%z":function(d){d=d.Ca;var m=0<=d;d=Math.abs(d)/60;return(m?"+":"-")+String("0000"+(d/60*100+d%60)).slice(-4)},"%Z":function(d){return d.Fa},"%%":function(){return"%"}};e=e.replace(/%%/g,"\x00\x00");for(r in v)e.includes(r)&&(e=e.replace(new RegExp(r,"g"),v[r](f)));e=e.replace(/\0\0/g,"%");r=Ha(e);if(r.length>c)return 0;P.set(r,a>>>0);return r.length-1}
var Ja={a:function(a){return ua(a+24)+24},b:function(a,c,e){(new ra(a)).ya(c,e);sa++;throw a;},g:function(){return 0},I:function(){},w:function(){},y:function(){},K:function(){return 0},G:function(){},C:function(){},F:function(){},k:function(){},x:function(){},u:function(){},H:function(){},v:function(){},n:function(){},p:function(){I("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},o:function(){I("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},
l:function(){return Date.now()},L:function(){return!0},M:function(a,c){a=new Date(1E3*(R[a>>>2]+4294967296*Q[a+4>>>2]));Q[c>>2>>>0]=a.getUTCSeconds();Q[c+4>>2>>>0]=a.getUTCMinutes();Q[c+8>>2>>>0]=a.getUTCHours();Q[c+12>>2>>>0]=a.getUTCDate();Q[c+16>>2>>>0]=a.getUTCMonth();Q[c+20>>2>>>0]=a.getUTCFullYear()-1900;Q[c+24>>2>>>0]=a.getUTCDay();Q[c+28>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},N:function(a,c){a=new Date(1E3*(R[a>>>2]+4294967296*Q[a+4>>>2]));Q[c>>2>>>0]=a.getSeconds();
Q[c+4>>2>>>0]=a.getMinutes();Q[c+8>>2>>>0]=a.getHours();Q[c+12>>2>>>0]=a.getDate();Q[c+16>>2>>>0]=a.getMonth();Q[c+20>>2>>>0]=a.getFullYear()-1900;Q[c+24>>2>>>0]=a.getDay();var e=new Date(a.getFullYear(),0,1);Q[c+28>>2>>>0]=(a.getTime()-e.getTime())/864E5|0;Q[c+36>>2>>>0]=-(60*a.getTimezoneOffset());var f=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();e=e.getTimezoneOffset();Q[c+32>>2>>>0]=(f!=e&&a.getTimezoneOffset()==Math.min(e,f))|0},O:function(a){var c=new Date(Q[a+20>>2>>>0]+1900,Q[a+16>>
2>>>0],Q[a+12>>2>>>0],Q[a+8>>2>>>0],Q[a+4>>2>>>0],Q[a>>2>>>0],0),e=Q[a+32>>2>>>0],f=c.getTimezoneOffset(),g=new Date(c.getFullYear(),0,1),h=(new Date(c.getFullYear(),6,1)).getTimezoneOffset(),l=g.getTimezoneOffset(),q=Math.min(l,h);0>e?Q[a+32>>2>>>0]=Number(h!=l&&q==f):0<e!=(q==f)&&(h=Math.max(l,h),c.setTime(c.getTime()+6E4*((0<e?q:h)-f)));Q[a+24>>2>>>0]=c.getDay();Q[a+28>>2>>>0]=(c.getTime()-g.getTime())/864E5|0;Q[a>>2>>>0]=c.getSeconds();Q[a+4>>2>>>0]=c.getMinutes();Q[a+8>>2>>>0]=c.getHours();Q[a+
12>>2>>>0]=c.getDate();Q[a+16>>2>>>0]=c.getMonth();return c.getTime()/1E3|0},z:function(){return-52},B:function(){},m:wa,d:function(){I("")},t:function(){return 4294901760},h:x?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:()=>performance.now(),J:function(a,c,e){M.copyWithin(a>>>0,c>>>0,c+e>>>0)},f:function(a){var c=M.length;a>>>=0;if(4294901760<a)return!1;for(var e=1;4>=e;e*=2){var f=c*(1+.2/e);f=Math.min(f,a+100663296);var g=Math;f=Math.max(a,f);g=g.min.call(g,4294901760,f+(65536-f%65536)%
65536);a:{try{J.grow(g-ha.byteLength+65535>>>16);ia();var h=1;break a}catch(l){}h=void 0}if(h)return!0}return!1},D:function(a,c){var e=0;Ba().forEach(function(f,g){var h=c+e;g=R[a+4*g>>2>>>0]=h;for(h=0;h<f.length;++h)P[g++>>0>>>0]=f.charCodeAt(h);P[g>>0>>>0]=0;e+=f.length+1});return 0},E:function(a,c){var e=Ba();R[a>>2>>>0]=e.length;var f=0;e.forEach(function(g){f+=g.length+1});R[c>>2>>>0]=f;return 0},r:function(a){if(!noExitRuntime){if(b.onExit)b.onExit(a);K=!0}t(a,new F(a))},e:function(){return 52},
j:function(){return 52},q:function(){return 70},i:function(a,c,e,f){for(var g=0,h=0;h<e;h++){var l=R[c>>2>>>0],q=R[c+4>>2>>>0];c+=8;for(var w=0;w<q;w++){var v=M[l+w>>>0],r=Da[a];0===v||10===v?((1===a?da:G)(fa(r,0)),r.length=0):r.push(v)}g+=q}R[f>>2>>>0]=g;return 0},s:X,A:Ia,c:function(a,c,e,f){return Ia(a,c,e,f)}};
(function(){function a(g){b.asm=g.exports;J=b.asm.P;ia();ka.unshift(b.asm.Q);S--;b.monitorRunDependencies&&b.monitorRunDependencies(S);0==S&&(null!==T&&(clearInterval(T),T=null),U&&(g=U,U=null,g()))}function c(g){a(g.instance)}function e(g){return qa().then(function(h){return WebAssembly.instantiate(h,f)}).then(function(h){return h}).then(g,function(h){G("failed to asynchronously prepare wasm: "+h);I(h)})}var f={a:Ja};S++;b.monitorRunDependencies&&b.monitorRunDependencies(S);if(b.instantiateWasm)try{return b.instantiateWasm(f,
a)}catch(g){return G("Module.instantiateWasm callback failed with error: "+g),!1}(function(){return H||"function"!=typeof WebAssembly.instantiateStreaming||na()||V.startsWith("file://")||x||"function"!=typeof fetch?e(c):fetch(V,{credentials:"same-origin"}).then(function(g){return WebAssembly.instantiateStreaming(g,f).then(c,function(h){G("wasm streaming compile failed: "+h);G("falling back to ArrayBuffer instantiation");return e(c)})})})().catch(k);return{}})();
b.___wasm_call_ctors=function(){return(b.___wasm_call_ctors=b.asm.Q).apply(null,arguments)};b._OrtInit=function(){return(b._OrtInit=b.asm.R).apply(null,arguments)};b._OrtCreateSessionOptions=function(){return(b._OrtCreateSessionOptions=b.asm.S).apply(null,arguments)};b._OrtAppendExecutionProvider=function(){return(b._OrtAppendExecutionProvider=b.asm.T).apply(null,arguments)};b._OrtAddSessionConfigEntry=function(){return(b._OrtAddSessionConfigEntry=b.asm.U).apply(null,arguments)};
b._OrtReleaseSessionOptions=function(){return(b._OrtReleaseSessionOptions=b.asm.V).apply(null,arguments)};b._OrtCreateSession=function(){return(b._OrtCreateSession=b.asm.W).apply(null,arguments)};b._OrtReleaseSession=function(){return(b._OrtReleaseSession=b.asm.X).apply(null,arguments)};b._OrtGetInputCount=function(){return(b._OrtGetInputCount=b.asm.Y).apply(null,arguments)};b._OrtGetOutputCount=function(){return(b._OrtGetOutputCount=b.asm.Z).apply(null,arguments)};
b._OrtGetInputName=function(){return(b._OrtGetInputName=b.asm._).apply(null,arguments)};b._OrtGetOutputName=function(){return(b._OrtGetOutputName=b.asm.$).apply(null,arguments)};b._OrtFree=function(){return(b._OrtFree=b.asm.aa).apply(null,arguments)};b._OrtCreateTensor=function(){return(b._OrtCreateTensor=b.asm.ba).apply(null,arguments)};b._OrtGetTensorData=function(){return(b._OrtGetTensorData=b.asm.ca).apply(null,arguments)};
b._OrtReleaseTensor=function(){return(b._OrtReleaseTensor=b.asm.da).apply(null,arguments)};b._OrtCreateRunOptions=function(){return(b._OrtCreateRunOptions=b.asm.ea).apply(null,arguments)};b._OrtAddRunConfigEntry=function(){return(b._OrtAddRunConfigEntry=b.asm.fa).apply(null,arguments)};b._OrtReleaseRunOptions=function(){return(b._OrtReleaseRunOptions=b.asm.ga).apply(null,arguments)};b._OrtRun=function(){return(b._OrtRun=b.asm.ha).apply(null,arguments)};
b._OrtEndProfiling=function(){return(b._OrtEndProfiling=b.asm.ia).apply(null,arguments)};var ua=b._malloc=function(){return(ua=b._malloc=b.asm.ja).apply(null,arguments)};b._free=function(){return(b._free=b.asm.ka).apply(null,arguments)};var Ka=b.stackSave=function(){return(Ka=b.stackSave=b.asm.ma).apply(null,arguments)},La=b.stackRestore=function(){return(La=b.stackRestore=b.asm.na).apply(null,arguments)},Ma=b.stackAlloc=function(){return(Ma=b.stackAlloc=b.asm.oa).apply(null,arguments)};
b.___cxa_is_pointer_type=function(){return(b.___cxa_is_pointer_type=b.asm.pa).apply(null,arguments)};b.UTF8ToString=L;b.stringToUTF8=function(a,c,e){return N(a,M,c,e)};b.lengthBytesUTF8=O;b.stackSave=Ka;b.stackRestore=La;b.stackAlloc=Ma;var Z;U=function Na(){Z||Oa();Z||(U=Na)};
function Oa(){function a(){if(!Z&&(Z=!0,b.calledRun=!0,!K)){W(ka);aa(b);if(b.onRuntimeInitialized)b.onRuntimeInitialized();if(b.postRun)for("function"==typeof b.postRun&&(b.postRun=[b.postRun]);b.postRun.length;){var c=b.postRun.shift();la.unshift(c)}W(la)}}if(!(0<S)){if(b.preRun)for("function"==typeof b.preRun&&(b.preRun=[b.preRun]);b.preRun.length;)ma();W(ja);0<S||(b.setStatus?(b.setStatus("Running..."),setTimeout(function(){setTimeout(function(){b.setStatus("")},1);a()},1)):a())}}
if(b.preInit)for("function"==typeof b.preInit&&(b.preInit=[b.preInit]);0<b.preInit.length;)b.preInit.pop()();Oa();


  return ortWasm.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasm; });
else if (typeof exports === 'object')
  exports["ortWasm"] = ortWasm;
